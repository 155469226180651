<!--
 * @Author: your name
 * @Date: 2021-11-22 15:56:04
 * @LastEditTime: 2021-12-13 14:01:24
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/index.vue
-->
<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "FeedBack"
}
</script>

<style lang="less" scoped>

</style>
